<template>
  <v-container class="mb-6 mt-10">
    <div class="text-start mb-5">すべてのお知らせ</div>
    <v-row>
      <template v-if="notifications.results.length === 0">
        <v-col class="text-center">
          <v-icon large color="yellow darken-4">mdi-alert-decagram</v-icon><span class="font-weight-bold">お知らせはありません</span>
        </v-col>
      </template>
      <template v-else>
        <v-col cols="12" md="12">
          <base-pagination
            :length="notifications.length"
            :selected-page="selectedPage"
            :total-visible="5"
            @input="onPageClick"
          />
        </v-col>
        <v-col
          v-for="item in notifications.results"
          cols="12"
          md="12"
          :key="item.id"
        >
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card
                class="rounded-xl ps-5"
                :style="styles.app"
                @click="goToPage(item)"
                :elevation="hover ? 12 : 6"
              >
                <v-card-title
                  class="text-h6 font-weight-bold"
                  v-text="item.notification.title"
                  :class="!item.isRead ? 'badge' : ''"
                />

                <v-card-text v-html="nl2br(item.notification.content)" />

                <v-card-subtitle v-text="calcElapsedTime(item.notification.createdAt)" />
              </v-card>
            </template>
          </v-hover>
        </v-col>
        <v-col cols="12" md="12">
          <base-pagination
            :length="notifications.length"
            :selected-page="selectedPage"
            :total-visible="5"
            @input="onPageClick"
          />
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import AppMethodsMixin from '@/mixins/AppMethodsMixin';
import AppStyleMixin from '@/mixins/AppStyleMixin';
import BasePagination from '@/components/atoms/BasePagination';

export default {
  name: 'List',
  components: { BasePagination },
  mixins: [AppMethodsMixin, AppStyleMixin],
  data() {
    return {
      notifications: {
        results: []
      },
      selectedPage: 1
    };
  },
  methods: {
    /** ページ送りクリック */
    onPageClick(page) {
      this.selectedPage = page;
      this.gets(); // お知らせ一覧
    },
    /** お知らせクリック時の画面遷移処理 */
    async goToPage(item) {
      // お知らせを既読にする
      await this.$store.dispatch('userNotifications/read', item.id);
      const json = JSON.parse(item.notification.link);

      // 対象ページへ遷移
      // TODO: 同一画面に遷移する場合は再読込
      this.$router.push(json, () => {});
    },
    /** クエリパラメータを取り出す（ブラウザバックで戻ってきた場合とか） */
    parseQueryParams() {
      // ページ数
      if (this.$route.query['page']) {
        this.selectedPage = Number(this.$route.query['page']);
      }
    },
    // お知らせ一覧取得
    async gets(exec) {
      // URLにクエリパラメータ追加
      let queryParams = [];
      if (this.selectedPage) {
        queryParams.push(`page=${this.selectedPage}`);
      }
      const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
      if (exec) { 
        history.pushState(null, null, queryString); // 検索ボタン押下時は履歴に残す
      } else {
        history.replaceState(null, null, queryString); // 初期表示時は履歴に残さない
      }

      // お知らせ検索
      this.notifications = await this.$store.dispatch('userNotifications/list', queryString);
      this.selectedPage = this.notifications.currentPage;
    }
  },
  async mounted() {
    this.showLoadingOverlay(async () => {
      this.parseQueryParams(); // クエリパラメータを取り出す（ブラウザバックされた時などを考慮）
      await this.gets(); // お知らせ一覧取得
    });
  }
};
</script>

<style scoped>
.badge:before{
  content: "⚫︎";
  position: absolute;
  font-size: 20px;
  top: 16px;
  left: 10px;
  text-align: center;
  border-radius: 50%;
  color: #EF5350;
}
</style>
